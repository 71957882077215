import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Box,
    useColorModeValue,
    GridItem,
    Grid,
    useToast,
    Heading,
    Divider,
    Text,
    FormControl,
    FormLabel,
    Input,
    RadioGroup,
    HStack,
    Radio,
    Progress,
    Tooltip,
    Stack,
    Alert,
    AlertIcon,
    AlertTitle,
} from "@chakra-ui/react";
import { CONFIG } from "../utils/constants";
import React from "react";

export default function CombineReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [report, setReport] = useState<any>({});
    const [filteredReport, setFilteredReport] = useState<any[]>([]);
    const [reportType, setReportType] = useState<any>("daily");
    const [filterType, setFilterType] = useState<any>("all");
    const [reportDate, setReportDate] = useState(moment().format("YYYY-MM-DD"));
    const toast = useToast();
    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchData = async () => {
        try {
            setIsLoading(true);
            let url = `${CONFIG.API_URL}/reports/combined_report_jira`;
            let queryParams: any = {};
            if (moment(reportDate).isValid()) {
                queryParams.report_date = reportDate;
            }
            if (reportType) {
                queryParams.report_type = reportType;
            }
            const obj: any = { params: queryParams };
            const result = await axios.get(url, obj);
            setReport(result.data.data);
            // filterReport();

            setIsLoading(false);
            toast({
                position: 'top-right',
                title: 'Report updated',
                status: 'success',
                duration: 3000,
                isClosable: true
            });
        } catch (error) {
            setIsLoading(false);
            toast({
                position: 'top-right',
                title: 'Unable to fetch report',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    };

    function onDateChange(date: any) {
        setReportDate(date);
    }

    function filterReport() {
        if (Object.keys(report).length == 0) {
            return;
        }
        if (filterType == 'jira') {
            const fr = report.report.filter((rp: any) => rp.server_jira_diff < 0);
            setFilteredReport(fr);
        } else if (filterType == 'server') {
            const fr = report.report.filter((rp: any) => rp.server_jira_diff > 0);
            setFilteredReport(fr);
        } else {
            setFilteredReport(report.report);
        }
    }

    useEffect(() => {
        filterReport();
    }, [filterType, report])

    useEffect(() => {
        fetchData();
    }, [reportDate, reportType]);

    return (
        <Grid py={5} gap={5} templateColumns={'350px 1fr'}>
            <GridItem>
                <Box
                    boxShadow={'2xl'}
                    my={4}
                    padding={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <Heading fontSize={"xl"} mb={2}>Combine Report</Heading>
                    <Heading color="gray" fontSize={"sm"} mb={2}>Period: {report.report_period}</Heading>
                    <Divider mb={2} />
                    <form>
                        <FormControl py={3}>
                            <FormLabel >Report Type:</FormLabel>
                            <RadioGroup onChange={setReportType} value={reportType}>
                                <HStack spacing={3}>
                                    <Radio value="daily">Daily</Radio>
                                    <Radio value="weekly">Weekly</Radio>
                                    <Radio value="monthly">Monthly</Radio>
                                </HStack>
                            </RadioGroup>
                        </FormControl>
                        <FormControl py={3}>
                            <FormLabel htmlFor="report_date">Report Date:</FormLabel>
                            <Input type="date" value={reportDate} onChange={(e) => onDateChange(e.target.value)}></Input>
                        </FormControl>
                    </form>
                </Box>

                <Box
                    boxShadow={'2xl'}
                    my={4}
                    padding={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <Heading fontSize={"xl"} mb={2}>Report Details</Heading>
                    <Divider mb={2} />
                    <Heading fontSize={"md"} mb={2}>Period: {report.report_period}</Heading>
                    <Heading fontSize={"md"} mb={2}>Working Days: {report.working_days}</Heading>
                    <Heading fontSize={"md"} mb={2}>Working Hours: {report.working_hours}</Heading>
                    {/* <Divider mb={2} />
                    <Heading fontSize={"xl"} mb={2}>Filter</Heading>
                    <Divider mb={2} />
                    <FormControl>
                        <RadioGroup onChange={setFilterType} value={filterType}>
                            <Stack direction={'column'}>
                                <Radio value="all">All</Radio>
                                <Radio value="jira">Jira is more then server</Radio>
                                <Radio value="server">Jira is less then server </Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl> */}
                </Box>
            </GridItem>
            <GridItem>
                <Box
                    boxShadow={'2xl'}
                    my={4}
                    padding={4}
                    borderWidth="1px"
                    borderRadius="lg"
                    bg={useColorModeValue('white', 'gray.900')}>
                    <TableContainer>
                        {isLoading ? <Progress size='xs' isIndeterminate variant='' /> : <></>}
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th>Employee</Th>
                                    <Th>SSPL</Th>
                                    <Th>NextGen</Th>
                                    <Th>Total Jira Hours</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    filteredReport.map((rp: any, ind: number) => (
                                        <Tr key={rp.uid}>
                                            <Td>{ind + 1}</Td>
                                            <Td>{rp.firstname} {rp.lastname}</Td>
                                            <Td textAlign="center">{rp.sspl.timespent_total_formatted}</Td>
                                            <Td textAlign="center">{rp.nextgen.timespent_total_formatted}</Td>
                                            <Td textAlign="center">{rp.total_reported_time_formatted}</Td>
                                        </Tr>
                                    ))
                                }
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </GridItem>
        </Grid>
    );
}