import React from 'react';
import {
  ChakraProvider,
  theme
} from '@chakra-ui/react';
import Layout from "./layout/layout";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Employees from './pages/Employees';
import EmployeeDetails from './pages/EmployeeDetails';
import Dashboard from './pages/Dashboard';
import Skills from './pages/Skills';
import Services from './pages/Services';
import Designations from './pages/Designations';
import EmployeeStatuses from './pages/EmployeeStatuses';
import ServerHoursReport from './pages/ServerHoursReport';
import Signin from './pages/auth/Signin';
import AddEmployeeForm from './components/AddEmployeeForm';
import UserProfile from './pages/Profile';
import ChangePassword from './pages/ChangePassword';
import CombineReport from './pages/CombineReport';
import UserStore from "./stores/UserStore";
import { Provider } from "react-redux";
import NotFoundPage from './pages/PageNotFound';
import EditEmployeeForm from './components/EditEmployeeForm';
import ReactGA from "react-ga4";
import Analytics from './pages/Analytics';
import OneSignal from "react-onesignal";
import Attandance from './pages/Attandance';

// init ga
ReactGA.initialize("G-NDFYZG1VK7");

// Init OneSignal Push
OneSignal.init({
  appId: '47b96f6b-ac83-4f80-b9f4-f8fa3249edab',
  allowLocalhostAsSecureOrigin: true
}).then((oi) => {
}).catch((e) => {
  console.log("Unable to init onesignal SDK. ", e);
});

function App() {

  return (
    <Provider store={UserStore}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Signin />} />
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="profile" element={<UserProfile />} />
              <Route path="change_password" element={<ChangePassword />} />
              <Route path="services" element={<Services />} />
              {/* <Route path="attandance" element={<Attandance />} /> */}
              <Route path="skills" element={<Skills />} />
              <Route path="designations" element={<Designations />} />
              <Route path="employee_statuses" element={<EmployeeStatuses />} />
              <Route path="employee_add" element={<AddEmployeeForm />} />
              <Route path="employees" element={<Employees />} />
              <Route path="employees/:uid" element={<EmployeeDetails />} />
              <Route path="employees/:uid/edit" element={<EditEmployeeForm />} />
              {/* <Route path="server_hours" element={<ServerHoursReport />} /> */}
              <Route path="combine_report" element={<CombineReport />} />
              <Route path="analytics" element={<Analytics />} />
              <Route path="*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
