import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    MenuButton,
    Menu,
    Avatar,
    MenuList,
    MenuItem,
    MenuDivider,
    HStack,
    useToast,
    Image,
} from '@chakra-ui/react';
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
} from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '../utils/constants';
import React, { useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import jwtdecode from "jwt-decode";
import { setAdmin, setUid, setProfile, setRoles, resetUser } from "../stores/ProfileSlice";
import axios, { AxiosError } from "axios";
import OneSignal from "react-onesignal";

export default function Header() {
    const toast = useToast();
    const { isOpen, onToggle } = useDisclosure();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isLoggedIn = useSelector((state: any) => state.user.uid);
    const userRoles: string[] = useSelector((state: any) => state.user.roles);
    const userProfile = useSelector((state: any) => state.user.profile);

    function handleSignout() {
        localStorage.removeItem(CONFIG.TOKEN_KEY);
        dispatch(resetUser());
        navigate("login");
    }

    const token = localStorage.getItem(CONFIG.TOKEN_KEY);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const fetchUserProfile = async () => {
        try {
            const result = await axios.get(`${CONFIG.API_URL}/user/profile`);
            dispatch(setProfile(result.data.data));
            // check if OS is subscribed
            OneSignal.Slidedown.promptPush();
            // set OneSignal UserID
            OneSignal.User.addAlias('external_id', result.data.data.uid);
            OneSignal.User.addAlias('member', result.data.data.fullname);
        } catch (error) {
            console.log("Error: ", error);
            if (error instanceof AxiosError) {
                if (error.response?.status == 401) {
                    handleSignout();
                } else {
                    toast({
                        position: 'top-right',
                        title: error.response ? error.response.data.message : error.message,
                        status: 'error',
                        duration: 3000,
                        isClosable: true
                    });
                }
            } else {
                toast({
                    position: 'top-right',
                    title: "Oops! Unable to signin. Please try again",
                    status: 'error',
                    duration: 3000,
                    isClosable: true
                });
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem(CONFIG.TOKEN_KEY);
        if (token) {
            // decode token to get role
            const decoded: any = jwtdecode(token);
            dispatch(setUid(decoded.uid));
            dispatch(setRoles(decoded.roles));
            if (decoded.roles.includes('ADMIN') || decoded.roles.includes('HR')) {
                dispatch(setAdmin(true));
            } else {
                dispatch(setAdmin(false));
            }

            // re-fetch user's profile in background
            if (userProfile == null) {
                fetchUserProfile();
            }
        } else {
            localStorage.removeItem(CONFIG.TOKEN_KEY);
            dispatch(resetUser());
            navigate("login");
            toast({
                position: 'top-right',
                title: 'Please login to continue',
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    }, []);

    return (
        <Box>
            <Flex
                bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}>
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
                    <Image src="/SSPL_Full_Logo_Horizontal.svg" width="150px" />

                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <DesktopNav userRoles={userRoles} />
                    </Flex>
                </Flex>

                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    {
                        (!isLoggedIn) ?
                            <Button
                                as={'a'}
                                fontSize={'sm'}
                                fontWeight={400}
                                variant={'link'}
                                href={'/login'}>
                                Sign In
                    </Button>
                            :
                            <Menu>

                                <MenuButton
                                    as={Button}
                                    rounded={'full'}
                                    variant={'link'}
                                    cursor={'pointer'}
                                >
                                    <HStack spacing={3}>
                                        <Avatar size={'sm'} src={'#'} name={(userProfile != null) ? userProfile.firstname + " " + userProfile.lastname : ""}></Avatar>
                                        <Text>{(userProfile != null) ? userProfile.firstname + " " + userProfile.lastname : ""}</Text>
                                    </HStack>
                                </MenuButton>
                                <MenuList>
                                    <MenuItem as='a' href='/profile'>Profile</MenuItem>
                                    <MenuItem as='a' href="/change_password">Change Password</MenuItem>
                                    <MenuDivider />
                                    <MenuItem onClick={handleSignout}>Logout</MenuItem>
                                </MenuList>
                            </Menu>
                    }
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav userRoles={userRoles} />
            </Collapse>
        </Box>
    );
}

const DesktopNav = (props: any) => {
    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem, ind) => (
                (roleHasPermission(navItem.allowedRoles, props.userRoles)) ?
                    <NavLinkItem key={navItem.label} label={navItem.label} href={navItem.href} />
                    :
                    <Fragment key={ind}></Fragment>
            ))}
        </Stack>
    );
};

const NavLinkItem = ({ label, href }: any) => {
    return (
        <Box key={label}>
            <Popover trigger={'hover'} placement={'bottom-start'}>
                <PopoverTrigger>
                    <Link
                        p={2}
                        href={href ?? '#'}
                        fontSize={'sm'}
                        fontWeight={500}
                        color='gray.600'
                        _hover={{
                            textDecoration: 'none',
                            color: 'gray.800',
                        }}>
                        {label}
                    </Link>
                </PopoverTrigger>
            </Popover>
        </Box>
    )
}

const MobileNav = (props: any) => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem, ind) => (
                (roleHasPermission(navItem.allowedRoles, props.userRoles)) ?
                    <MobileNavItem key={navItem.label} {...navItem} />
                    :
                    <Fragment key={ind}></Fragment>
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: any) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                        children.map((child: any) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

const NAV_ITEMS = [
    {
        label: 'Home',
        href: '/',
        allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    },
    {
        label: 'Services',
        href: '/services',
        allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    },
    // {
    //     label: 'Attandance',
    //     href: '/attandance',
    //     allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    // },
    {
        label: 'Skills',
        href: '/skills',
        allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    },
    {
        label: 'Designations',
        href: '/designations',
        allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    },
    {
        label: 'Employee Statuses',
        href: '/employee_statuses',
        allowedRoles: ['ADMIN', 'HR']
    },
    {
        label: 'Employees',
        href: '/employees',
        allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    },
    // {
    //     label: 'Server Hours',
    //     href: '/server_hours',
    //     allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    // },
    {
        label: 'Jira Report',
        href: '/combine_report',
        allowedRoles: ['ADMIN', 'EMPLOYEE', 'HR']
    },
    {
        label: 'Analytics',
        href: '/analytics',
        allowedRoles: ['ADMIN']
    },
];

function roleHasPermission(allowedRoles: string[], userRoles: string[]): boolean {
    return allowedRoles.some(r => userRoles.includes(r));
}